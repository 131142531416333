import { useEffect, useState } from "react"
import Collapsible from "react-collapsible"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import { formatFacetName, formatPriceRange, isColorFacet } from "@/utils/helper"
import {
  getFilterOptionsAnalyticsData,
  getShowHideFilterOptionAnalyticsData,
} from "@/components/ProductList/v2/Filter/filterAnalytics"
import { PriceRangeFrench } from "@/constants"

const CollapsibleFacet = ({
  facets,
  filterTitle,
  page,
  type = "",
  appliedFilters,
  loading,
  onChange,
  isSelected,
  facetLabels,
  selectedTab,
  colors,
  showFilter,
  isAnnsacksPlp = false,
}) => {
  const [collapse, setCollapse] = useState({})
  const facet = facets[0]
  const facetList = facets[1]
  const facetName = formatFacetName(facets[0], null, facetLabels)
  const facetCount = facets[1].length / 2

  const handleCollapse = state => {
    const action = { [facet]: state }
    setCollapse({ ...action })
  }

  useEffect(() => {
    if (appliedFilters.length > 0) {
      appliedFilters.forEach(obj => {
        const action = collapse || {}
        action[obj.facet] = true
        setCollapse({ ...action })
      })
    } else {
      setCollapse({})
    }
  }, [appliedFilters, showFilter])

  const handleOnChange = (event, obj) => {
    onChange(event.target?.checked, obj)
    try {
      const { adobeDataLayer: { getState } = {} } = window
      const pageEvent = (getState && getState("page")) || {}

      const eventInfo = JSON.parse(
        getFilterOptionsAnalyticsData({
          obj,
          appliedFilters,
          facetLabels,
          facetName,
          isSelected,
          page,
          selectedTab,
        }) || "{}"
      )

      window.adobeDataLayer.push({
        event: "cmp:click",
        page: pageEvent,
        eventInfo,
      })
    } catch (err) {
      // console.log(err)
    }
  }

  return (
    <Collapsible
      tabIndex="0"
      open={collapse[facet] && showFilter ? true : false}
      onTriggerOpening={() => handleCollapse(true)}
      onTriggerClosing={() => handleCollapse(false)}
      openedClassName="is-open"
      trigger={
        <div
          aria-label={filterTitle(facetName, facetCount)}
          aria-expanded={collapse[facet] ? true : false}
          className="gbh-data-layer"
          data-gbh-data-layer={getShowHideFilterOptionAnalyticsData(
            collapse,
            facet,
            facetName,
            page,
            selectedTab
          )}
        >
          <span aria-hidden="true">{facetName}</span>
          <section className="plus">
            <div className="line-1 line"></div>
            <div className="line line-2"></div>
          </section>
        </div>
      }
      transitionTime={400}
      easing="ease-in-out"
    >
      {collapse[facet] && (
        <>
          <ul role="list" className="filter-list">
            {facetList.map((filter, i, arr) => {
              if (i % 2 === 0) {
                let displayText = null
                if (facet === "Price_Range" || facet === PriceRangeFrench) {
                  displayText = formatPriceRange(filter, isAnnsacksPlp)
                }

                const obj = {
                  facet: facet,
                  value: filter,
                  display: displayText ?? filter,
                }
                return (
                  <li role="listitem" key={facet + filter}>
                    <Checkbox
                      role="checkbox"
                      aria-checked="false"
                      key={filter}
                      id={facet + filter}
                      disabled={loading}
                      checkBoxAria={
                        facet === "Price_Range" || facet === PriceRangeFrench
                          ? obj.display
                          : `${obj.display} (${arr[i + 1]})`
                      }
                      tabIndex={collapse[facet] ? 0 : -1}
                      value={
                        facet === "Price_Range" || facet === PriceRangeFrench
                          ? obj.display
                          : `${obj.display} (${arr[i + 1]})`
                      }
                      onChange={e => handleOnChange(e, obj)}
                      checked={isSelected(obj)}
                      swatch={isColorFacet(facet) ? colors[filter] : null}
                    />
                  </li>
                )
              }
            })}
          </ul>
        </>
      )}
    </Collapsible>
  )
}
export default CollapsibleFacet
