import { formatFacetName, retainSearchQueryParams } from "@/utils/helper"
const setUrlParams = (
  filters,
  isReplace,
  type,
  facetLabels,
  resetTitle,
  clearAllFilters = () => {}
) => {
  let query = window.location.search
  if (query.length && !query.includes("tab") && type)
    query += isReplace ? "" : `${query.includes("?") ? "&" : "?"}tab=${type}`
  if (filters.length > 0) {
    query += query ? "&" : ""
    filters.forEach((filter, i) => {
      const values = [...filter.value]
      const searches = location.search.split("&")
      const index = searches.findIndex(search => search.includes(filter.facet))
      if (index > -1) {
        searches.splice(index, 1)
        query = searches.join("&")
        query += query ? "&" : ""
      }
      if (
        values.length !== 0 && filter.facet === "Price_Range"
          ? true
          : facetLabels[filter.facet?.replace(/^\*{2}/, "")]
      ) {
        query += `${
          filter.facet === "Price_Range"
            ? filter.facet
            : facetLabels[filter.facet?.replace(/^\*{2}/, "")]?.replace(
                / /g,
                "_"
              ) ?? filter.facet
        }=${
          filter.facet === "Price_Range"
            ? filter.value
            : encodeURIComponent(values)
        }${i !== filters.length - 1 ? "&" : ""}`
      }
    })
    if (resetTitle) resetTitle()
  } else {
    clearAllFilters(false)
  }
  const queryStr = query.toString()
  const queryParam =
    queryStr.length === 0
      ? window.location.pathname
      : queryStr.includes("?")
      ? queryStr
      : `?${queryStr}`
  query &&
    window.history.replaceState(
      window.location.pathname,
      document.title,
      queryParam
    )
  if (type) retainSearchQueryParams(type)
}

const getAppliedFiltersForAnalytics = (appliedFilters, facetLabels) => {
  const filterArray = []
  appliedFilters.forEach(filter => {
    filter.display.forEach(filtervalue => {
      filterArray.push(
        `${formatFacetName(filter.facet, null, facetLabels)}:${filtervalue}`
      )
    })
  })
  return filterArray.length ? filterArray.join("|") : ""
}

export { setUrlParams, getAppliedFiltersForAnalytics }
