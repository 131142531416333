import React, { useEffect, useRef, useState } from "react"
import { useRouter } from "next/router"
import printIcon from "@/public/icons/print.svg"
import shareIcon from "@/public/icons/share.svg"
import closeIcon from "@/public/icons/close-filter.svg"
import Image from "next/image"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import RadioButton from "@/components/core/RadioButton/RadioButton"
import useWindowResize from "@/hooks/useWindowResize"
import cx from "classnames"
import CollapsibleFacet from "@/components/ProductList/v2/Filter/collapsibleFacet"
import cloneDeep from "lodash/cloneDeep"
import {
  formatFacetName,
  resetFilterUrl,
  retainSearchQueryParams,
} from "@/utils/helper"
import { setUrlParams } from "@/components/ProductList/v2/Filter/helper"
import {
  getAnalyticsData,
  addFilterCancelDataLayerOnClick,
  getFilterClearAllAnalyticsData,
  getFilterCloseAnalyticsData,
  getFilterCloseImgAnalyticsData,
  getFilterViewResultsAnalyticsData,
  getPrintOrShareEventInfo,
  getSocialShareAnalyticsData,
} from "@/components/ProductList/v2/Filter/filterAnalytics"
import SocialShare from "@/components/SocialShare/v1/SocialShare"

const Filter = props => {
  const {
    showFilter = false,
    selectedSort = {},
    facetLabels = [],
    totalResults = 0,
    hidePriceRange,
    loading,
    discontinuedCheck,
    displayPrint = false,
    displayShare = false,
    facetData = [],
    sort = [],
    showSort = false,
    onSortChange = () => {},
    texts: {
      filterSort = "",
      clearAll = "",
      filters = "",
      selected = "",
      sortByAria = "",
      sort: sortText = "",
      filterTitle = () => {},
      discontinuedText = "",
      hideDiscontinued = "",
      arialLabelPrint = () => {},
      print = "",
      ariaLabelShare = () => {},
      share = "",
      view = "",
      results = "",
    },
    colors = {},
    addToUrl = false,
    onFilter = () => {},
    handleScroll = () => {},
    page = "",
    currFilters,
    isReplace,
    resetTitle,
    type = "",
    close = () => {},
    isSearchTab,
    isAnnsacksPlp = false,
    isFaq = false,
    wrapperClass = ".product-listing__tile",
    actions = [],
    needHelpLabel = "",
  } = props

  const router = useRouter()
  const [width] = useWindowResize()

  const plpFilter = useRef(null)
  const [appliedFilters, setAppliedFilters] = useState([])
  const [showSocialLink, setShowSocialLink] = useState(false)
  const [scrollInstance, setScrollInstance] = useState(null)

  const sortBy = useRef()
  const socialShareREf = useRef()

  const selectedTab = type || "products"

  useEffect(() => {
    if (currFilters && currFilters.length > 0) {
      setAppliedFilters(currFilters)
    } else {
      setAppliedFilters([])
    }
  }, [currFilters])

  useEffect(() => {
    window.wow?.sync()
    const instance = window.OverlayScrollbars
      ? // eslint-disable-next-line new-cap
        window.OverlayScrollbars(plpFilter?.current, {
          className: "os-theme-dark os-host-flexbox",
          resize: "none",
          sizeAutoCapable: true,
          clipAlways: true,
          normalizeRTL: true,
          paddingAbsolute: false,
          autoUpdate: null,
          autoUpdateInterval: 33,
          nativeScrollbarsOverlaid: {
            showNativeScrollbars: false,
            initialize: true,
          },
          overflow: {
            x: "hidden",
          },
          scrollbars: {
            visibility: "auto",
            autoHide: "never",
            autoHideDelay: 800,
            dragScrolling: true,
            clickScrolling: true,
            touchSupport: true,
            snapHandle: true,
          },
        })
      : null
    setScrollInstance(instance)
  }, [])

  let filterList = { ...facetData }

  delete filterList["New_Products"]
  delete filterList["Hide_Discontinued"]
  delete filterList["Products"]
  delete filterList["Inspiration"]
  delete filterList["Technical"]
  delete filterList["Parts"]
  delete filterList["Help"]
  delete filterList["Resource"]

  if (hidePriceRange) {
    delete filterList["Price_Range"]
  } else if (filterList["Price_Range"] && router.locale === "fr") {
    filterList = {
      "priceList.ANY.finalPrice_d": filterList[`Price_Range`],
      ...filterList,
    }
    delete filterList["Price_Range"]
  } else if (filterList["Price_Range"]) {
    filterList = {
      Price_Range: filterList[`Price_Range`],
      ...filterList,
    }
  }

  const facets = Object.entries(filterList).filter(facet => facet[1].length > 0)

  const handleFilter = (checked, obj) => {
    let curFilters = cloneDeep(appliedFilters)
    const facet = curFilters.find(filter => filter.facet === obj.facet)
    if (checked) {
      if (facet) {
        facet.value.push(obj.value.replace(/"/g, '\\"'))
        facet.display.push(obj.display)
      } else
        curFilters.push({
          facet: obj.facet,
          value: [obj.value.replace(/"/g, '\\"')],
          display: [obj.display],
        })
    } else {
      const index = facet.display.indexOf(obj.display)
      facet.value.splice(index, 1)
      facet.display.splice(index, 1)

      if (!facet.value.length) {
        curFilters = curFilters.filter(f => f.facet !== obj.facet)
      }
    }
    resetFilterUrl()
    setAppliedFilters([...curFilters])
    addToUrl &&
      setUrlParams(
        curFilters,
        isReplace,
        isFaq ? "" : type,
        facetLabels,
        resetTitle,
        clearAllFilters
      )
    onFilter(curFilters)
    handleScroll && handleScroll()
  }

  const clearAllFilters = (isOnFilter = true) => {
    setAppliedFilters([])
    isOnFilter && onFilter([])
    resetFilterUrl()
    if (resetTitle) resetTitle()
    if (type) retainSearchQueryParams(type)
  }

  const printPlp = () => {
    scrollToAllProduct()
    setTimeout(() => {
      window.print()
    }, 1250)
  }

  const scrollToAllProduct = () => {
    const scorllObjInterval = setInterval(() => {
      const scrollPositions = document.querySelectorAll(
        `${wrapperClass} div.LazyLoad:not(.is-visible)`
      )
      if (scrollPositions.length > 0) {
        scrollPositions[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        })
      } else {
        clearInterval(scorllObjInterval)
      }
    }, 150)
    setTimeout(() => {
      clearInterval(scorllObjInterval)
    }, 2000)
  }

  const handleShare = e => {
    e.stopPropagation()
    e.preventDefault()
    setShowSocialLink(!showSocialLink)
    setTimeout(() => {
      const shareEl = socialShareREf.current
      scrollInstance?.scroll({ el: shareEl, block: "center" }, 500)
    }, 100)
    try {
      const { adobeDataLayer: { getState } = {} } = window
      const eventInfo = JSON.parse(e?.target?.dataset?.gbhDataLayer || "{}")
      const page = (getState && getState("page")) || {}

      window.adobeDataLayer.push({
        event: "cmp:click",
        page,
        eventInfo,
      })
    } catch (err) {
      // console.log(err)
    }
  }

  const isSelected = obj => {
    const facet = appliedFilters.find(filter => filter.facet === obj.facet)
    if (!facet) return false
    return facet.display.includes(obj.display) ? true : false
  }
  return (
    <div ref={plpFilter} className={cx("product-listing filter")}>
      <div className="filter__inner-container">
        {!isSearchTab && (
          <div className="product-listing__controls filter__mobile-header">
            <div
              className="product-listing__filter-toggle gbh-data-layer"
              data-gbh-data-layer={getFilterCloseAnalyticsData(page)}
            >
              <span>{filterSort}</span>
            </div>
            <img
              tabIndex="0"
              role="button"
              src={closeIcon?.src}
              className="product-listing__close-icon gbh-data-layer"
              data-gbh-data-layer={getFilterCloseImgAnalyticsData()}
              alt="Close Filter"
              onClick={() => close(false)}
            />
          </div>
        )}

        <div className="filter__chips">
          {appliedFilters.map(facet => {
            return facet.display.map(filter => (
              <div className="filter__chips-tag" key={filter}>
                <span>
                  {" "}
                  {filter === "Yes" ||
                  filter === "No" ||
                  !isNaN(parseInt(filter))
                    ? formatFacetName(facet["facet"], filter, facetLabels)
                    : filter}
                </span>
                <Image
                  src={closeIcon}
                  tabIndex="0"
                  role="button"
                  aria-label={`Remove filter ${
                    filter === "Yes" || filter === "No"
                      ? formatFacetName(facet["facet"], filter, facetLabels)
                      : filter
                  }`}
                  alt="close Icon"
                  className="filter__chips-close-icon gbh-data-layer"
                  onClick={() => {
                    handleFilter(false, {
                      facet: facet.facet,
                      display: filter,
                    })
                    addFilterCancelDataLayerOnClick(
                      "cancel",
                      appliedFilters,
                      facet,
                      filter,
                      selectedTab,
                      page,
                      facetLabels
                    )
                  }}
                />
              </div>
            ))
          })}
          {appliedFilters.length > 0 && (
            <div
              className="filter__chips-clear-all gbh-data-layer"
              tabIndex="0"
              role="button"
              aria-label={`${clearAll} ${filters}`}
              data-gbh-data-layer={getFilterClearAllAnalyticsData(
                selectedTab,
                page
              )}
              onClick={clearAllFilters}
            >
              {clearAll}
            </div>
          )}
        </div>

        {showSort && (
          <div className="filter__mobile-sort">
            <div role="radiogroup" aria-labelledby="sortBy-radio">
              <h2
                id="sortBy-radio"
                className="filter__mobile-sort-title"
                aria-label={
                  selectedSort ? `${selectedSort.name} ${selected}` : sortByAria
                }
              >
                {`${sortText} ${selectedSort ? ": " + selectedSort.name : ""}`}
              </h2>
              <div className="radio-wrapper" ref={sortBy}>
                {sort.map((item, i) => {
                  return (
                    <RadioButton
                      key={i}
                      id={`sort-by-${item.name}`}
                      name={`sort-by`}
                      value={item.name}
                      checked={item.name === selectedSort?.name}
                      onClick={() => onSortChange(item)}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
        {totalResults > 0 && (
          <div role="list" className="filter__filter-list">
            {facets.map(facet => {
              return (
                <div className="collapsible-outer" role="listitem" key={facet}>
                  <CollapsibleFacet
                    facets={facet}
                    showFilter={showFilter}
                    facetLabels={facetLabels}
                    filterTitle={filterTitle}
                    appliedFilters={appliedFilters}
                    loading={loading}
                    onChange={handleFilter}
                    isSelected={isSelected}
                    colors={colors}
                    selectedTab={selectedTab}
                    page={page}
                    type={type}
                    isAnnsacksPlp={isAnnsacksPlp}
                  />
                </div>
              )
            })}
          </div>
        )}

        {discontinuedCheck && (
          <div className="filter__hide-discontinued">
            <div>
              <Checkbox
                id="discontinued"
                onChange={e =>
                  handleFilter(e.target.checked, {
                    facet: "Hide_Discontinued",
                    value: "true",
                    display: discontinuedText,
                  })
                }
                checked={isSelected({
                  facet: "Hide_Discontinued",
                  value: "true",
                  display: discontinuedText,
                })}
                value={hideDiscontinued}
                analyticsData={getAnalyticsData(
                  isSelected({
                    facet: "Hide_Discontinued",
                    value: "true",
                    display: discontinuedText,
                  })
                )}
              />
            </div>
          </div>
        )}

        <div className="filter__print-and-share">
          {displayPrint !== undefined && displayPrint && (
            <span
              tabIndex="0"
              role="button"
              aria-label={arialLabelPrint(page)}
              className="gbh-data-layer"
              hidden={width < 992}
              data-gbh-data-layer={getPrintOrShareEventInfo(
                "print",
                selectedTab,
                page
              )}
              onClick={() => printPlp()}
            >
              <img src={printIcon?.src} alt="print-icon" role="presentation" />
              {print}
            </span>
          )}
          {displayShare !== undefined && displayShare && (
            <span
              tabIndex="0"
              role="button"
              aria-label={ariaLabelShare(page)}
              className="gbh-data-layer"
              hidden={width < 992}
              data-gbh-data-layer={getPrintOrShareEventInfo(
                "share",
                selectedTab,
                page
              )}
              onClick={handleShare}
            >
              <img src={shareIcon?.src} alt="share-icon" role="presentation" />
              {share}
            </span>
          )}
        </div>
        <SocialShare
          showModal={showSocialLink}
          id="kf-plp-filter"
          onClose={() => setShowSocialLink(false)}
          analyticData={getSocialShareAnalyticsData(page, selectedTab)}
          shareRef={socialShareREf}
        />
        {needHelpLabel || actions.length ? (
          <div className="filter__need-help-container">
            <div className="need-help-label">{needHelpLabel}</div>
            {actions.length ? (
              <>
                {actions.map((action, index) => (
                  <div
                    key={`fra32a-44s-${index}342-dag32f`}
                    className="need-help-links-container"
                  >
                    <a
                      className="need-help-link"
                      href={
                        action.url
                          ? action.url
                              .replace(
                                process.env.NEXT_PUBLIC_INTERNALLINKPATH,
                                ""
                              )
                              .replace(".html", "")
                          : ""
                      }
                    >
                      {action.title}
                    </a>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="filter__footer">
        <button
          className={cx("filter__apply gbh-data-layer")}
          data-gbh-data-layer={getFilterViewResultsAnalyticsData(
            facetLabels,
            page,
            appliedFilters
          )}
          onClick={() => close(false)}
        >
          {loading && <div className="filter__apply-loader"></div>}
          {`${view} ${results} (${totalResults})`}
        </button>
      </div>
    </div>
  )
}

export default Filter
